import React from 'react'
import { YMInitializer } from 'react-yandex-metrika'
import fbPixel from 'react-facebook-pixel'

const YA_METRIKA = 33869584
const FB_PIXEL = 631803343664918

export default function Metrics() {
  React.useEffect(() => {
    fbPixel.init(FB_PIXEL)
    fbPixel.pageView()
  }, [])

  return <YMInitializer accounts={[YA_METRIKA]} options={{ webvisor: true }} />
}
