import React from 'react'
import { Image } from '../../components/Image'
import cn from '../../helpers/classnames'
import constrain from '../../helpers/constrain'
import useBoundingClientRect from '../../helpers/useBoundingClientRect'
import { Section } from './common'
import bookImg from './img/tool-book.png'
import chiefImg from './img/tool-chief.png'
import conspectusImg from './img/tool-conspectus.png'
import courseImg from './img/tool-course.png'
import octopusImg from './img/tool-octopus.png'
import schoolImg from './img/tool-school.png'
import s from './styles.module.css'

const TOOLS_WIDTH = 2260

export default function Tools() {
  const rootRef = React.useRef()
  const sectionRef = React.useRef()
  const { top } = useBoundingClientRect(rootRef)
  const { width, height } = useBoundingClientRect(sectionRef)
  const maxDiff = TOOLS_WIDTH - width
  const rootHeight = maxDiff + height || 0
  const diff = constrain(top, [-maxDiff, 0])

  return (
    <div className='relative' style={{ height: rootHeight }} ref={rootRef}>
      <div className='sticky top-0'>
        <div className='overflow-hidden'>
          <Section>
            <div ref={sectionRef}>
              <div
                className='flex py-12'
                style={{
                  width: TOOLS_WIDTH,
                  transform: `translateX(${diff}px)`,
                  willChange: 'transform',
                }}
              >
                <a
                  className={cn('mr-4', s.linkable)}
                  href='/chief/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=bottom'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image src={chiefImg} style={{ width: 380 }} />
                  <div className={cn('mt-2', s.hoverable)}>
                    Услуга
                    <br />
                    <span className={s.fontBold}>Шефство</span>
                  </div>
                </a>

                <div className='mr-4 -mt-8'>
                  <a
                    className={cn('flex justify-end mb-4', s.linkable)}
                    href='/conspectus/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=bottom'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Image
                      src={conspectusImg}
                      className='mr-4'
                      style={{ width: 160, height: 160 }}
                    />
                    <div className={s.hoverable}>
                      Инструмент
                      <br />
                      <span className={s.fontBold}>Конспект</span>
                    </div>
                  </a>

                  <a
                    className={s.linkable}
                    href='https://bureau.ru/educenter/fff-online/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=bottom'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Image src={courseImg} style={{ width: 380 }} />
                    <div className={cn('mt-2', s.hoverable)}>
                      Практический курс
                      <br />
                      <span className={s.fontBold}>
                        Управление проектами,
                        <br />
                        людьми и&nbsp;собой
                      </span>
                    </div>
                  </a>
                </div>

                <a
                  className={cn('mr-4 -mt-4', s.linkable)}
                  href='https://bureau.ru/projects/book-fff/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=bottom'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image src={bookImg} style={{ width: 460 }} />
                  <div className={s.hoverable}>
                    Книга
                    <br />
                    <span className={s.fontBold}>
                      Управление проектами,
                      <br />
                      людьми и&nbsp;собой
                    </span>
                  </div>
                </a>

                <a
                  className={cn('mr-4', s.linkable)}
                  href='/octopus/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=bottom'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image src={octopusImg} style={{ width: 280 }} />
                  <div className={cn('mt-2', s.hoverable)}>
                    Визуализация навыков
                    <br />
                    <span className={s.fontBold}>Осьминожка</span>
                  </div>
                </a>

                <a
                  className={cn('relative', s.linkable)}
                  href='https://bureau.ru/school/managers/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=bottom'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Image src={schoolImg} style={{ width: 680 }} />
                  <div className={cn('absolute top-4 left-4', s.hoverable)}>
                    Школа бюро
                    <br />
                    <span className={s.fontBold}>Школа руководителей</span>
                  </div>
                </a>
              </div>
            </div>
          </Section>
        </div>
      </div>
    </div>
  )
}
