import React from 'react'
import { Image } from '../../components/Image'
import {
  DividerSection,
  Grid2md4,
  Card,
  SquareSpacer,
  CardLink,
  H3,
} from './common'
import imgCat from './img/cat.png'
import imgGun from './img/gun.png'

export default function Tips() {
  return (
    <DividerSection style={{ overflow: 'hidden' }}>
      <Grid2md4>
        <CardLink href='https://bureau.ru/books/fff/demo/15'>
          <Card style={{ overflow: 'visible' }}>
            <SquareSpacer />
            <Image
              src={imgGun}
              style={{
                position: 'absolute',
                width: '90%',
                top: '-11%',
                left: '6%',
              }}
              alt='Обложка игры с пистолетом'
            />
          </Card>
        </CardLink>
        <CardLink href='https://bureau.ru/books/fff/demo/17'>
          <Card style={{ overflow: 'visible' }}>
            <SquareSpacer />
            <Image
              src={imgCat}
              style={{
                position: 'absolute',
                width: '132%',
                maxWidth: 'unset',
                top: '-13%',
                left: '-4%',
              }}
              alt='Обложка игры с котом'
            />
          </Card>
        </CardLink>
        <div className='hidden md:block' />
        <div>
          <H3>Игры из&nbsp;книги об&nbsp;управ&shy;лении проектами</H3>
        </div>
      </Grid2md4>
    </DividerSection>
  )
}
