import React from 'react'
import { Image } from '../../components/Image'
import { Section, Inner10, H2 } from './common'
import bookImg from './img/book.png'

export default function Book() {
  return (
    <Section>
      <Inner10 className='md:pb-10'>
        <div className='md:border border-black border-opacity-10 rounded-3xl'>
          <div className='md:grid grid-cols-2 gap-4'>
            <div
              style={{
                width: '120%',
                maxWidth: '120%',
                marginTop: '-8%',
                marginBottom: '-11%',
                marginLeft: '-20%',
              }}
              className='grid'
            >
              <Image src={bookImg} className='m-auto' />
            </div>
            <div className='mt-12 md:mb-14 md:pr-12'>
              <H2>Практика и&nbsp;теория ФФФ</H2>
              <p>
                Инструкция самодостаточна, но&nbsp;хорошо дополняет книгу{' '}
                <span className='text-cyan'>«</span>
                <a
                  href='https://bureau.ru/projects/book-fff/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=book'
                  className='link link-cyan'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Управление проектами, людьми и&nbsp;собой
                </a>
                <span className='text-cyan'>»</span>: в&nbsp;книге теория,
                в&nbsp;рассылке&nbsp;— практика.
              </p>
            </div>
          </div>
        </div>
      </Inner10>
    </Section>
  )
}
