import React from 'react'
import { Image } from '../../components/Image'
import { Section, Inner8, H2 } from './common'
import lettersImg from './img/letters.png'

export default function Checklists() {
  return (
    <div className='overflow-hidden'>
      <Section>
        <Inner8>
          <H2>Шпаргалка с&nbsp;чеклистами</H2>
          <p className='mb-6'>
            В&nbsp;конце каждой части инструкции&nbsp;— чеклист для проверки
            себя на&nbsp;текущем этапе проекта.
          </p>
        </Inner8>
        <div
          style={{
            width: '123%',
            maxWidth: '123%',
            margin: '-9% -11.5% 0',
          }}
        >
          <Image src={lettersImg} />
        </div>
      </Section>
    </div>
  )
}
