import React from 'react'
import { Image } from '../../components/Image'
import { Section, Inner10, Inner8, H2 } from './common'
import kolanImg from './img/kolan.jpg'

export default function Author() {
  return (
    <Section id='author' className='-mt-8'>
      <Inner8>
        <H2>Автор рассылки&nbsp;— Николай Товеровский</H2>
      </Inner8>
      <Inner10>
        <Image src={kolanImg} className='rounded-2xl' />
      </Inner10>
      <Inner8>
        <div className='md:grid grid-cols-2 gap-4 mt-6'>
          <div>
            <p className='mb-4'>
              <span className='text-purple'>В бюро:</span>
              <br />
              <a
                href='https://bureau.ru/projects/onetwotrip/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                Раз-два-трип
              </a>
              , журнал «
              <a
                href='https://bureau.ru/projects/glavbukh-print/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                Главбух
              </a>
              », система «
              <a
                href='https://bureau.ru/projects/kadry-2/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                Кадры
              </a>
              » и&nbsp;
              <a
                href='https://bureau.ru/projects/amundsen-and-scott/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                Диаграмма Амундсена и&nbsp;Скотта
              </a>
              .
            </p>
            <p className='mb-4 md:mb-0'>
              <span className='text-drive'>В DRIVE2.RU:</span>
              <br />
              <a
                href='https://ksoftware.livejournal.com/354786.html?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                приложение и&nbsp;мобильный сайт
              </a>
              , «
              <a
                href='https://ksoftware.livejournal.com/401204.html?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                Бизнес-акаунты
              </a>
              ».
            </p>
          </div>
          <div>
            <p className='mb-4'>
              <span className='text-dodo'>В «Додо Пицце»:</span>
              <br />
              <a
                href='https://ksoftware.livejournal.com/335478.html?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                американский сайт
              </a>
              ,{' '}
              <a
                href='https://ksoftware.livejournal.com/319469.html?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                промо франшизы
              </a>
              ,{' '}
              <a
                href='https://ksoftware.livejournal.com/323040.html?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                база знаний
              </a>
              , сайт «
              <a
                href='https://ksoftware.livejournal.com/324295.html?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                Одного миллиарда
              </a>
              ».
            </p>
            <p>
              Пишу{' '}
              <a
                href='https://bureau.ru/bb/soviet/kolan/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                советы
              </a>
              , преподаю{' '}
              <a
                href='https://bureau.ru/school/designers/11/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                в&nbsp;школе
              </a>{' '}
              и&nbsp;написал{' '}
              <a
                href='https://bureau.ru/projects/book-fff/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=author'
                className='link link-black'
                target='_blank'
                rel='noopener noreferrer'
              >
                книгу об&nbsp;управлении проектами
              </a>
              .
            </p>
          </div>
        </div>
      </Inner8>
    </Section>
  )
}
