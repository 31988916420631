const colors = {
  yellow: '#ffd000',
  ultramarine: '#2c159d',
  purpur: '#e44a66',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mobileBreakpoint: '1023px',
  ...colors,
}
