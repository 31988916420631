import React from 'react'
import SubscriptionForm from '../../components/SubscriptionForm/SubscriptionForm'
import { Image } from '../../components/Image'
import { MainSection, H2 } from './common'
import imgShelf from './img/blog-shelf.jpg'
import imgFalcon from './img/blog-falcon.png'
import imgCalendar from './img/blog-calendar.png'
import imgFive from './img/blog-five.jpg'
import imgPomodoro from './img/blog-pomodoro.png'

export default function Subscription() {
  return (
    <MainSection id='subscription'>
      <H2 className='mb-1 text-center'>Сообщество руководителей</H2>
      <div className='text-center'>
        Обсуждаем всё, что связано с управлением.
        <br />
        Помогаем разрулить рабочие и личные ситуации.
      </div>
      <div className='flex mt-8 mb-20'>
        <div className='md:w-9/12 lg:w-6/12 mx-auto'>
          <SubscriptionForm />
        </div>
      </div>
      <div
        className='relative flex flex-wrap overflow-hidden'
        style={{ marginBottom: '-5rem', paddingTop: '10%' }}
      >
        <a
          href='/blog'
          target='_blank'
          rel='noopener noreferrer'
          className='w-full mb-1 text-sm text-cyan hover:text-black transition'
        >
          Блог об&nbsp;ФФФ
        </a>
        <a
          className='w-6/12'
          href='/blog/all/shit-in-cells/'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            marginBottom: '-8%',
          }}
        >
          <Image src={imgShelf} alt='Средневековая полка' />
        </a>
        <a
          className='w-6/12 absolute'
          href='/blog/all/task-calendar/'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            top: 0,
            right: '17%',
          }}
        >
          <Image src={imgCalendar} alt='Скриншот программы «Календарь»' />
        </a>
        <a
          className='w-3/12 absolute'
          href='/blog/all/shit-in-cells/'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            top: '24%',
            right: '6%',
          }}
        >
          <Image src={imgPomodoro} alt='Скриншот программы «Помодоро»' />
        </a>
        <a
          className='w-9/12 absolute'
          href='/blog/all/falcon-heavy/'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            top: '50%',
            right: 0,
          }}
        >
          <Image
            src={imgFalcon}
            alt='Разворот из книги с планом пуска ракеты'
          />
        </a>
        <a
          className='w-3/12 absolute'
          href='/blog/all/caterpillar-nails/'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            top: '65%',
            right: '6%',
          }}
        >
          <Image src={imgFive} alt='Плакат с цифрой пять' />
        </a>
      </div>
    </MainSection>
  )
}
