import React from 'react'
import { Image } from '../../components/Image'
import cn from '../../helpers/classnames'
import constrain from '../../helpers/constrain'
import useBoundingClientRect from '../../helpers/useBoundingClientRect'
import asses1Img from './img/asses-1.png'
import asses2Img from './img/asses-2.png'
import asses3Img from './img/asses-3.png'
import rocketImg from './img/rocket.png'
import s from './styles.module.css'

const asses = [
  { src: asses1Img, diff: 60, zIndex: 100 },
  { src: asses2Img, diff: 80, zIndex: 200 },
  { src: asses3Img, diff: 100, zIndex: 300 },
]

export default function Asses() {
  const ref = React.useRef()
  const { top, height } = useBoundingClientRect(ref)
  const coef = constrain(-top / height, [0, 1]) || 0
  const rocketDiff = 10 * (coef - 0)

  return (
    <div
      className='flex absolute max-w-6xl top-0 bottom-0 w-full pointer-events-none'
      style={{ left: '50%', transform: 'translateX(-50%)' }}
      ref={ref}
    >
      <div className='flex relative'>
        {asses.map(({ src, diff, zIndex }, idx) => (
          <Image
            src={src}
            className={cn('absolute will-transform', s.asses)}
            style={{
              zIndex,
              transform: `translate3D(
              ${3 * diff * coef}%,
              ${3 * diff * coef}%,
              0
            )`,
            }}
            key={idx}
          />
        ))}
        <div
          className={'relative max-w-6xl mx-auto mt-auto mb-40 md:mb-72'}
          style={{ zIndex: 250 }}
        >
          <Image
            src={rocketImg}
            className={cn('will-transform', s.rocket)}
            style={{
              transform: `translate3D(${rocketDiff}%, ${-rocketDiff}%, 0)`,
            }}
          />
        </div>
      </div>
    </div>
  )
}
