import React from 'react'
import ym from 'react-yandex-metrika'
import Nav from '../../components/Nav/Nav'
import cn from '../../helpers/classnames'
import smoothScrollToAnchor from '../../helpers/smoothScrollToAnchor'

const nav = {
  left: [
    [
      'ФФФ.воркс',
      '/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=home',
    ],
  ],
  center: [
    ['Что в инструкции', 'instruction', 'GPDG_INSTRUCTION_TOPMENU'],
    ['Кто автор', 'author', 'GPDG_AUTHOR_TOPMENU'],
    ['Подписаться', 'subscribe', 'GPDG_SUBSCRIBE_TOPMENU'],
  ],
  right: [
    [
      'Блог',
      '/blog/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=blog',
    ],
    [
      'Сообщество',
      '/community/?utm_source=fff-works&utm_medium=gpdg&utm_campaign=promo&utm_content=community',
    ],
  ],
}

const Left = (
  <div>
    {nav.left.map((el, idx) => (
      <a href={el[1]} className='link link-white' key={idx}>
        {el[0]}
      </a>
    ))}
  </div>
)

const Center = (
  <div className='hidden md:block'>
    {nav.center.map((el, idx) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role='button'
        tabIndex='-1'
        className={cn(
          'link link-gold',
          idx !== nav.center.length - 1 && 'mr-4'
        )}
        onClick={() => {
          ym('reachGoal', el[2])
          smoothScrollToAnchor(el[1])()
        }}
        key={idx}
      >
        {el[0]}
      </a>
    ))}
  </div>
)

const Right = (
  <div>
    {nav.right.map((el, idx) => (
      <a
        className={cn(
          'link link-white',
          idx !== nav.right.length - 1 && 'mr-4'
        )}
        target='_blank'
        rel='noopener noreferrer'
        href={el[1]}
        key={idx}
      >
        {el[0]}
      </a>
    ))}
  </div>
)

export default function NavBar() {
  return <Nav left={Left} center={Center} right={Right} />
}
