import { useState, useEffect } from 'react'

export default function useViewportSize() {
  const [size, setSize] = useState({})
  const handleViewportSize = () =>
    setSize({
      viewportWidth: document.documentElement.clientWidth,
      viewportHeight: document.documentElement.clientHeight,
    })

  useEffect(() => {
    handleViewportSize()
    window.addEventListener('resize', handleViewportSize)
    return () => window.removeEventListener('resize', handleViewportSize)
  }, [])

  return size
}
