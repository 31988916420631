import React from 'react'
import ym from 'react-yandex-metrika'
import cn from '../../helpers/classnames'
//import smoothScrollToAnchor from '../../helpers/smoothScrollToAnchor'
import { Section, Inner8, H1 } from './common'
import Asses from './Asses'
import NavBar from './NavBar'
import Cloud from './Cloud'
import AB from './AB'
import s from './styles.module.css'

export default function Intro() {
  return (
    <div className='relative overflow-hidden'>
      <div className='max-w-6xl mx-auto'>
        <Cloud
          className='absolute'
          style={{ transform: 'translate3d(-40%, -20%, 0)' }}
        />
      </div>
      <div className='relative transform translate-z-0'>
        <NavBar />
        <Section className='z-0'>
          <Inner8>
            <div className='text-center'>
              <div>Инструкция</div>
              <H1>Как сделать проект</H1>
              <div className='mx-auto max-w-sm'>
                Учит планировать и&nbsp;запускать проекты в&nbsp;срок и&nbsp;без
                потери качества
              </div>
            </div>
          </Inner8>
        </Section>
        <div className='max-w-6xl mx-auto'>
          <Cloud
            className='absolute'
            style={{ transform: 'translate3d(40%, -5%, 0) rotate(180deg)' }}
          />
        </div>
        <AB />
      </div>
      <Asses />
      <Section>
        <Inner8>
          <button
            className={cn(
              'relative mb-4 md:mb-12',
              s.largeInput,
              s.purpleButton
            )}
            style={{ zIndex: 350 }}
            onClick={() => {
              ym('reachGoal', 'GPDG_SUBSCRIBE_MAINBUTTON')
              // smoothScrollToAnchor('subscribe')()
              window.open(
                'https://boosty.to/fffworks/posts/5e02cbd0-2b9c-4c71-9cbc-d81fc738800f',
                '_blank'
              )
            }}
          >
            Подписаться на&nbsp;Бусти
          </button>
        </Inner8>
      </Section>
    </div>
  )
}
