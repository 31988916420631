import React from 'react'
import { FORM_ERROR } from 'final-form'
import { Field, Form } from 'react-final-form'
import s from './SubscriptionForm.module.css'

const postResponse = ({ email }) =>
  fetch('/api/v1/users/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, username: email }),
  })

const subscribe = async (values) => {
  try {
    const res = await postResponse(values)

    if (!res.ok) throw new Error()
  } catch (error) {
    return { [FORM_ERROR]: 'Проверьте адрес почты' }
  }
}

export default function SubscriptionForm() {
  return (
    <Form
      onSubmit={subscribe}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        dirtySinceLastSubmit,
        submitError,
        submitSucceeded,
      }) => (
        <form onSubmit={handleSubmit} className='flex flex-col'>
          <div className={s.field}>
            <a
              className='bg-cyan hover:bg-cyan-dark text-white hover:text-white'
              target='_blank'
              rel='noopener noreferrer'
              href='/community'
            >
              Присоединиться к&nbsp;сообществу на&nbsp;Бусти
            </a>
          </div>
          <div className={s.field}>
            <div className='text-center'>или подписаться на рассылку</div>
          </div>
          <div className={s.field}>
            <Field
              name='email'
              component='input'
              type='email'
              placeholder='Эл. почта'
            />
            <input
              type='submit'
              className={`transition text-white hover:text-white ${
                submitError && !dirtySinceLastSubmit
                  ? 'bg-red-500'
                  : 'bg-cyan hover:bg-cyan-dark'
              }`}
              style={{ transform: 'translateZ(0)' }}
              disabled={
                submitting ||
                pristine ||
                (submitSucceeded && !dirtySinceLastSubmit)
              }
              value={submitSucceeded && !dirtySinceLastSubmit ? '✓' : 'Го!'}
            />
          </div>
          <div className={s.field}>
            <div className='text-sm text-center'>
              {submitError || 'Примеры и практические советы'}
            </div>
          </div>
        </form>
      )}
    />
  )
}
