/* eslint-disable jsx-a11y/heading-has-content */

import React from 'react'

export const Grid2md4 = (props) => (
  <div
    {...props}
    className={'grid grid-cols-2 md:grid-cols-4 gap-4 ' + props.className}
  />
)

export const Grid1md2 = (props) => (
  <div
    {...props}
    className={'grid grid-cols-1 md:grid-cols-2 gap-4 ' + props.className}
  />
)

export const Title = (props) => (
  <div
    {...props}
    className={
      'text-2xl md:text-3xl lg:text-4xl max-w-2xl leading-tight ' +
      props.className
    }
  />
)

export const H2 = (props) => (
  <h2
    {...props}
    className={'text-3xl font-bold leading-tight ' + props.className}
  />
)

export const H3 = (props) => (
  <h3
    {...props}
    className={'text-2xl font-bold mb-1 leading-tight ' + props.className}
  />
)

export const H3Like = (props) => (
  <div
    {...props}
    className={'text-2xl font-bold mb-1 leading-tight ' + props.className}
  />
)

export const CardLink = (props) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    {...props}
    className='linkable'
    target='_blank'
    rel='noopener noreferrer'
  />
)

export const DividerSection = (props) => (
  <section
    className='text-white bg-dark pt-8 pb-16'
    id={props.id || ''}
    style={props.style}
  >
    <div className='xl:container mx-auto px-4'>{props.children}</div>
  </section>
)

export const DividerLabel = (props) => (
  <div className='text-white mt-3 hoverable' {...props} />
)

export const MainSection = (props) => (
  <section className='xl:container mx-auto px-4' id={props.id || ''}>
    <div className='pt-8 pb-16'>{props.children}</div>
  </section>
)

export const MainLabel = (props) => (
  <div className='hoverable mt-4' {...props} />
)

export const Card = (props) => (
  <div
    {...props}
    className='relative shadow-card rounded-2xl overflow-hidden'
  />
)

export const SquareSpacer = (props) => (
  <div className='bg-white rounded-2xl pb-full' {...props} />
)
