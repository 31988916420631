import React from 'react'
import { Image } from '../../components/Image'
import cn from '../../helpers/classnames'
import constrain from '../../helpers/constrain'
import useBoundingClientRect from '../../helpers/useBoundingClientRect'
import useViewportSize from '../../helpers/useViewportSize'
import { H2, Inner8, Section } from './common'
import checkmarkImg from './img/checkmark.png'
import whiteNailImg from './img/white-nail.png'
import whiteRocketImg from './img/white-rocket.png'

const cards = [
  {
    title: 'Зна\u00adком\u00adство с за\u00adдачей',
    cls: 'bg-gold',
    x: 30,
    y: 30,
  },
  {
    title: 'Пони\u00adмание задачи',
    cls: 'bg-gold',
    x: 40,
    y: -15,
  },
  { title: 'План про\u00adекта', cls: 'bg-gold', x: 190, y: -40 },
  { title: 'Старт про\u00adекта', cls: 'bg-gold', x: -90, y: 70 },
  {
    title: 'Пер\u00adвая итера\u00adция',
    cls: 'bg-gold',
    x: -250,
    y: 190,
  },
  {
    title: 'Скуч\u00adная сере\u00adдина',
    cls: 'bg-gold',
    x: 380,
    y: -10,
  },
  {
    title: 'Точка невоз\u00adврата',
    cls: 'bg-cyan text-white',
    x: -25,
    y: 30,
    image: whiteNailImg,
  },
  {
    title: 'Поли\u00adровка и до\u00adжим',
    cls: 'bg-gold',
    x: 100,
    y: -100,
  },
  {
    title: 'Запуск про\u00adекта',
    cls: 'bg-cyan text-white',
    x: -160,
    y: 50,
    image: whiteRocketImg,
  },
  { title: 'Дора\u00adботка после пуска', cls: 'bg-gold', x: -320, y: 0 },
]

const CardOfProject = ({ title, cls, x, y, coef, image }) => {
  const diffX = x * coef
  const diffY = y * coef
  const opacity = coef > 0.2 ? 0 : 1 - coef * 5 || 0
  const scale = 1 + coef * 5 || 1

  return (
    <div
      className={cn(
        'relative rounded-lg p-1 md:p-3 h-24 bg-opacity-90 shadow-2xl will-transform',
        cls
      )}
      style={{
        transform: `translate(${diffX}%, ${diffY}%)`,
      }}
    >
      <div className='text-xs leading-tight md:text-base md:leading-tight lg:text-xl lg:leading-tight hyphens-manual sm:hyphens-none'>
        {title}
      </div>
      <Image
        src={checkmarkImg}
        className='absolute -top-1 md:-top-2 right-0 md:right-1 w-3 md:w-4 h-3 md:h-5 transition will-ransform'
        style={{
          opacity: opacity,
          transform: `scale(${scale})`,
        }}
      />
      {image && (
        <Image
          src={image}
          className='absolute bottom-0 right-0 w-8 md:w-10 h-8 md:h-10'
        />
      )}
    </div>
  )
}

export default function Instruction() {
  const ref = React.useRef()
  const { top } = useBoundingClientRect(ref)
  const { viewportHeight } = useViewportSize()
  const coef = constrain(top / viewportHeight, [0, 1])

  return (
    <div>
      <Section id='instruction'>
        <Inner8 className='pt-4 md:pt-12'>
          <H2>Инструкция по&nbsp;«сделыванию» проекта</H2>
          <div ref={ref} />
          <p>
            Последовательно рассказываем, как сделать каждый этап проекта.
            Рассказы объединяются в&nbsp;инструкцию, по&nbsp;которой
            вы&nbsp;сможете сделать свои проекты.
          </p>
        </Inner8>
        <div className='mt-6'>
          <div className='border border-black border-opacity-10 rounded-lg'>
            <div className='grid grid-cols-5'>
              {cards.map((card, idx) => (
                <div
                  className={cn(
                    'border-black border-opacity-10 p-0.5 md:p-1',
                    idx < 5 && 'border-b',
                    idx !== 4 && idx !== 9 && 'border-r'
                  )}
                  key={idx}
                >
                  <CardOfProject {...card} coef={coef} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}
