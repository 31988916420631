import React from 'react'
import fbPixel from 'react-facebook-pixel'
import ym from 'react-yandex-metrika'
import cn from '../../helpers/classnames'
import { H2, Inner8, Section } from './common'
import s from './styles.module.css'

export default function Subscription() {
  return (
    <Section id='subscribe' className='transform translate-z-0'>
      <Inner8 className='pt-12'>
        <div className='bg-gold text-black px-4 rounded-3xl'>
          <H2 className='pt-10' style={{ marginBottom: '1rem' }}>
            Подписка на&nbsp;инструкцию
          </H2>
          <p className='mb-6'>
            Чтобы прочитать инструкцию, подпишитесь на&nbsp;уровень
            &laquo;В&nbsp;теме&raquo; на&nbsp;нашей странице в&nbsp;сервисе
            &laquo;Бусти&raquo;.
          </p>
          <button
            className={cn('z-0', s.largeInput, s.purpleButton)}
            onClick={() => {
              ym('reachGoal', 'GPDG_SUBSCRIBE_START')
              fbPixel.track('InitiateCheckout')
              window.open(
                'https://boosty.to/fffworks/posts/5e02cbd0-2b9c-4c71-9cbc-d81fc738800f',
                '_blank'
              )
            }}
          >
            Подписаться на&nbsp;Бусти
          </button>
          <div
            className='py-6 opacity-50 text-center'
            style={{ visibility: 'hidden' }}
          >
            <a
              href='/agreement'
              className='link link-black'
              target='_blank'
              rel='noopener noreferrer'
            >
              Соглашение и&nbsp;защита данных
            </a>
          </div>
        </div>
        <div className='text-center'>
          <div className='py-8' style={{ display: 'none' }}>
            или присоединиться{' '}
            <a
              href='/community'
              target='_blank'
              rel='noopener noreferrer'
              className='link link-white'
            >
              к&nbsp;сообществу руководителей
            </a>
          </div>
        </div>
      </Inner8>
    </Section>
  )
}
