import React from 'react'
import { Helmet } from 'react-helmet'
import opengraphImg from '../assets/chief/facebook-chief.png'

export default function Meta(props) {
  return (
    <Helmet>
      <title>Посоветоваться с&nbsp;шефом</title>
      <meta property='og:title' content='Посоветоваться с&nbsp;шефом' />
      <meta
        property='og:description'
        content='Чтобы расти в&nbsp;профессии эффективно, найдите наставника, который поможет составить план роста и&nbsp;не&nbsp;даст его провалить.'
      />
      <meta property='og:url' content='http://fff.works/chief' />
      <meta property='og:image' content={opengraphImg} />
      <meta name='twitter:card' content='summary_large_image' />

      {props.children}
    </Helmet>
  )
}
