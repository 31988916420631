import React from 'react'
import { Image } from '../../components/Image'
import constrain from '../../helpers/constrain'
import useBoundingClientRect from '../../helpers/useBoundingClientRect'
import useViewportSize from '../../helpers/useViewportSize'
import { H2, Inner8, Section } from './common'
import templateImg from './img/template.png'
import bombImg from './img/case-bomb.png'
import fireImg from './img/case-fire.png'
import nailImg from './img/case-nail.png'
import questionImg from './img/case-question.png'
import skullImg from './img/case-skull.png'
import spinnerImg from './img/case-spinner.png'

const cards = [
  {
    title: 'Сделали раньше дедлайна',
    image: nailImg,
    top: 30,
    left: 5,
    x: -40,
    y: -10,
  },

  {
    title: 'Директор оттягивает срок',
    image: spinnerImg,
    top: 5,
    left: 50,
    x: 40,
    y: -10,
  },
  {
    title: 'Не понял, но делаю',
    image: questionImg,
    top: 35,
    left: 70,
    x: 40,
    y: -10,
  },
  {
    title: 'Бесхозная задача',
    image: skullImg,
    top: 50,
    left: 40,
    x: 40,
    y: -10,
  },
  {
    title: 'Дедлайн сорван',
    image: fireImg,
    top: 75,
    left: 10,
    x: -40,
    y: -10,
  },
  {
    title: 'Срок — не алё',
    image: bombImg,
    top: 65,
    left: 60,
    x: 40,
    y: -10,
  },
]

const Card = ({ title, image, top, left, x, y, coef }) => (
  <div
    className={`
      absolute flex w-24 md:w-64 md:h-16 p-2 md:p-3 rounded-md md:rounded-xl bg-black bg-opacity-70
      text-white hover:text-white text-xxs md:text-sm shadow-2xl will-transform
    `}
    style={{
      top: `${top}%`,
      left: `${left}%`,
      transform: `
        translateX(${x * coef}%)
        translateY(${y * coef}%)
        scale(${1 + coef / 2})
      `,
    }}
  >
    <div className='absolute -top-1.5 -right-1.5 md:static w-4 md:w-10 h-4 md:h-10 rounded-full mr-1 md:mr-2'>
      <Image src={image} />
    </div>
    <div>
      <div className='md:-mb-0.5 text-xxxs md:text-xxs opacity-50'>
        Ситуация в проекте
      </div>
      <div>{title}</div>
    </div>
  </div>
)

export default function Templates() {
  const ref = React.useRef()
  const { top } = useBoundingClientRect(ref)
  const { viewportHeight } = useViewportSize()
  const coef = constrain(top / viewportHeight, [0, 1])

  return (
    <div className='overflow-hidden' ref={ref}>
      <Section>
        <Inner8 className='md:w-5/12'>
          <H2>Шаблоны и&nbsp;примеры</H2>
          <p>
            Показываем, как воплотить советы из&nbsp;инструкции
            на&nbsp;примерах. Забирайте себе и&nbsp;используйте.
          </p>
        </Inner8>
        <div className='relative md:-mt-40'>
          <Image
            src={templateImg}
            className='max-w-xs md:max-w-md lg:max-w-lg m-auto will-transform'
            style={{
              transform: `rotate(${45 - 10 * coef}deg)`,
            }}
          />
          {cards.map((card, idx) => (
            <Card {...card} coef={coef} key={idx} />
          ))}
        </div>
      </Section>
    </div>
  )
}
