import React from 'react'
import { Helmet } from 'react-helmet'
import opengraphImg from './img/getting-project-done-guide.png'

export default function Meta(props) {
  return (
    <Helmet>
      <title>Инструкция «Как сделать проект»</title>
      <meta property='og:title' content='Инструкция «Как сделать проект»' />
      <meta
        property='og:description'
        content='Инструкция «Как сделать проект» последовательно рассказывает о&nbsp;том, как подготовить проект перед стартом, обойти опасности на&nbsp;разных этапах работы, дожать, запустить проект в&nbsp;срок и&nbsp;позаботиться о&nbsp;нём после запуска.'
      />
      <meta
        property='og:url'
        content='https://fff.works/getting-project-done-guide/'
      />
      <meta property='og:image' content={opengraphImg} />

      {props.children}
    </Helmet>
  )
}
