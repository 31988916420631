import React from 'react'
import Meta from './Meta'
import Intro from './Intro'
import Instruction from './Instruction'
import Checklists from './Checklists'
import Author from './Author'
import Templates from './Templates'
import Letters from './Letters'
import Book from './Book'
import Feedbacks from './Feedbacks'
import Subscription from './Subscription'
import { InvertWrapper, Section } from './common'
import DebugGrid from '../../components/DebugGrid/DebugGrid'
import QA from './QA'
import Tools from './Tools'
import Footer from './Footer'
import Sky from './Sky'
import s from './styles.module.css'
import '../../fonts/stratos/stratos.css'

export default function GettingProjectDoneGuidePage() {
  return (
    <div className={s.fontNormal}>
      <DebugGrid />
      <Meta />

      <InvertWrapper>
        <Sky />
        <Intro />
      </InvertWrapper>
      <Instruction />
      <Checklists />
      <Section style={{ padding: 0, marginTop: '-13.5%' }} />
      <Templates />
      <Section style={{ padding: 0, marginTop: '-10.5%' }} />
      <Author />
      <Letters />
      <Book />
      <Feedbacks />
      <InvertWrapper>
        <Sky />
        <Subscription />
        <QA />
        <Tools />
        <Footer />
      </InvertWrapper>
    </div>
  )
}
