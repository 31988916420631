import React from 'react'
import { Image } from '../../components/Image'
import { pluralize } from 'helpers/pluralize'
import cn from '../../helpers/classnames'
import { Section, Inner8, H2 } from './common'
import avatarAntropova from './img/avatar-antropova.jpg'
import s from './styles.module.css'

const FEEDBACKS = [
  {
    authorImg: avatarAntropova,
    authorName: 'Анна Антропова',
    authorLink: 'https://www.facebook.com/anna.antropova.98',
    text: [
      `<span>Даже спустя полгода имею ясное представление о&nbsp;том, как
      делаются проекты</span>, и&nbsp;если возникнет такая задача, будет
      на&nbsp;что опереться. Спасибо вашей команде за&nbsp;подачу,
      за&nbsp;структуру и&nbsp;возможность интерактива. Вы&nbsp;задавали
      вопросы, я&nbsp;отвечала, где непонятно, где ошибки, и&nbsp;это помогло,
      очевидно, поставить те&nbsp;самые крючочки, на&nbsp;которые потом
      цеплялась информация. Даже при таком объёме сухого остатка очень довольна,
      что вписалась в&nbsp;рассылку.`,
    ],
  },
]
const VISIBLE_FEEDBACKS_SIZE = 1
const VISIBLE_FEEDBACKS = FEEDBACKS.slice(0, VISIBLE_FEEDBACKS_SIZE)
const OTHER_FEEDBACKS = FEEDBACKS.slice(
  VISIBLE_FEEDBACKS_SIZE,
  FEEDBACKS.length
)
const FEEDBACK_SUFFIX = { one: 'отзыв', two: 'отзыва', five: 'отзывов' }
const SHOW_MORE_LABEL = `Ещё\u00a0${pluralize(
  OTHER_FEEDBACKS.length,
  FEEDBACK_SUFFIX
)}`
const HAS_OTHER_FEEDBACKS = Boolean(OTHER_FEEDBACKS.length)
const formatFeedbackText = (text) =>
  text.replace(/<span>/g, `<span class='${cn('text-purple', s.fontBold)}'>`)

function Feedback({ authorName, authorImg, authorLink, text }) {
  return (
    <div className='mt-6 mb-10 last:mb-0'>
      <div className='flex flex-row items-center mb-3'>
        <Image
          className='object-top object-cover w-10 h-10 rounded-full'
          alt={authorName}
          src={authorImg}
        />
        <p className='ml-3'>
          <a
            className={cn('link link-black', s.fontBold)}
            href={authorLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            {authorName}
          </a>
        </p>
      </div>
      <div>
        {text.map((p, idx) => (
          <p
            key={idx}
            className='mb-4 last:mb-0'
            dangerouslySetInnerHTML={{ __html: formatFeedbackText(p) }}
          />
        ))}
      </div>
    </div>
  )
}

export default function Comments() {
  const [isAllShown, setIsAllShown] = React.useState(!HAS_OTHER_FEEDBACKS)

  return (
    <Section>
      <Inner8 className='pb-12'>
        <H2>Отзывы о&nbsp;рассылке</H2>
        {VISIBLE_FEEDBACKS.map((feedback) => (
          <Feedback key={feedback.authorName} {...feedback} />
        ))}
        {isAllShown &&
          OTHER_FEEDBACKS.map((feedback) => (
            <Feedback key={feedback.authorName} {...feedback} />
          ))}
        {HAS_OTHER_FEEDBACKS && !isAllShown && (
          <div className='-mt-5'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role='button'
              className='link link-purple'
              onClick={() => setIsAllShown(true)}
            >
              {SHOW_MORE_LABEL}
            </a>
          </div>
        )}
      </Inner8>
    </Section>
  )
}
