import React from 'react'

export default function Spacer({ ratio }) {
  const [width, height] = ratio.split('/')

  return (
    <div
      className='w-full h-0'
      style={{ paddingBottom: `${(height / width) * 100}%` }}
    />
  )
}
