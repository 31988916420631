import React from 'react'
import SubscriptionForm from '../../components/SubscriptionForm/SubscriptionForm'
import { MainSection, Title } from './common'

export default function Intro() {
  return (
    <MainSection>
      <div className='mb-12 md:w-9/12'>
        <Title>
          <span className='font-bold'>ФФФ</span>, fix time, fix budget, flex
          scope&nbsp;— подход к&nbsp;запуску проектов в&nbsp;срок без потери
          качества и&nbsp;перерасхода бюджета.
        </Title>
      </div>
      <div className='md:w-9/12 lg:w-6/12 mb-2'>
        <SubscriptionForm />
      </div>
    </MainSection>
  )
}
