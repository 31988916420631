import React from 'react'
import { Image } from '../../components/Image'
import {
  MainSection,
  MainLabel,
  Grid1md2,
  Card,
  SquareSpacer,
  CardLink,
  H2,
  H3Like,
} from './common'
import imgSubscription from './img/meteorit.png'
import imgBook from './img/book-fff.png'

export default function WhereToStart() {
  return (
    <MainSection id='where-to-start'>
      <H2 className='mb-6'>С чего начать</H2>
      <Grid1md2>
        <CardLink href='https://bureau.ru/projects/book-fff/?utm_source=fff-works&utm_medium=index'>
          <Card style={{ overflow: 'visible' }}>
            <SquareSpacer />
            <Image
              src={imgBook}
              style={{
                position: 'absolute',
                width: '100%',
                top: '-5%',
              }}
              alt='Обложка книги «Управление проектами, людьми и собой»'
            />
          </Card>
          <MainLabel>Электронная книга</MainLabel>
          <H3Like className='hoverable pr-5'>
            Управление проектами, людьми и&nbsp;собой
          </H3Like>
        </CardLink>
        <CardLink href='/getting-project-done-guide/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <SquareSpacer />
            <Image
              src={imgSubscription}
              style={{
                position: 'absolute',
                top: 0,
                left: '-32%',
                height: '100%',
                maxWidth: 'unset',
              }}
              alt='Обложка инструкции «Как сделать проект»'
            />
          </Card>
          <MainLabel>Инструкция</MainLabel>
          <H3Like className='hoverable'>Как сделать проект</H3Like>
        </CardLink>
      </Grid1md2>
    </MainSection>
  )
}
