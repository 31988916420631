import React from 'react'
import { Helmet } from 'react-helmet'

export default function AgreementPage() {
  return (
    <Helmet>
      <title>ФФФ.воркс</title>
      <meta
        httpEquiv='refresh'
        content='0;url=https://docs.google.com/document/d/1MTaRGIvCg9Q2CP3U2gDD3BYCXzjW2jClmDc1TtdOLwQ'
      />
    </Helmet>
  )
}
