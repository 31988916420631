import React from 'react'
import Meta from '../getting-project-done-guide/Meta'

export default function AgreementPage() {
  return (
    <Meta>
      <meta httpEquiv='refresh' content='0;url=/getting-project-done-guide' />
    </Meta>
  )
}
