import React from 'react'
import { Helmet } from 'react-helmet'

export default function ConspectusPage() {
  return (
    <Helmet>
      <title>Конспект&nbsp;— заметки на&nbsp;встрече</title>
      <meta
        httpEquiv='refresh'
        // eslint-disable-next-line no-restricted-globals
        content={`0;url=https://conspectus.io/${location.search}`}
      />
    </Helmet>
  )
}
