import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

export default function smoothScrollToAnchor(anchor) {
  return () => {
    history.replace(`#${anchor}`)
    document.getElementById(anchor).scrollIntoView({ behavior: 'smooth' })
  }
}
