import React from 'react'
import { Helmet } from 'react-helmet'
import ym from 'react-yandex-metrika'
import fbPixel from 'react-facebook-pixel'
import { Image } from '../../../components/Image'
import DebugGrid from '../../../components/DebugGrid/DebugGrid'
import Nav from '../../../components/Nav/Nav'
import cn from '../../../helpers/classnames'
import Footer from '../Footer'
import Sky from '../Sky'
import Tools from '../Tools'
import Cloud from '../Cloud'
import { H2, InvertWrapper, Section } from '../common'
import checkmarkImg from '../img/checkmark-white.png'
import s from '../styles.module.css'
import '../../../fonts/stratos/stratos.css'

const nav = {
  left: [['ФФФ.воркс', '/']],
  center: [],
  right: [
    ['Блог', '/blog'],
    ['Сообщество', '/community'],
  ],
}

const Left = (
  <div>
    {nav.left.map((el, idx) => (
      <a href={el[1]} className='link link-white' key={idx}>
        {el[0]}
      </a>
    ))}
  </div>
)

const Center = <div />

const Right = (
  <div>
    {nav.right.map((el, idx) => (
      <a
        className={cn(
          'link link-white',
          idx !== nav.right.length - 1 && 'mr-4'
        )}
        target='_blank'
        rel='noopener noreferrer'
        href={el[1]}
        key={idx}
      >
        {el[0]}
      </a>
    ))}
  </div>
)

export default function GettingProjectDoneGuidePage() {
  React.useEffect(() => {
    ym('reachGoal', 'GPDG_SUBSCRIBE_SUCCESS')
    fbPixel.track('Purchase', { currency: 'RUB', value: 2400 })
  }, [])

  return (
    <div className={s.fontNormal}>
      <DebugGrid />
      <Helmet>
        <title>Как сделать проект</title>
      </Helmet>

      <InvertWrapper>
        <Sky />
        <div className='max-w-6xl mx-auto'>
          <Cloud
            className='absolute'
            style={{ transform: 'translate3d(-40%, -20%, 0)' }}
          />
        </div>
        <div className='relative transform translate-z-0'>
          <Nav left={Left} center={Center} right={Right} />

          <Section className='text-center'>
            <div className='relative'>
              <H2 className='mx-auto'>
                <span className='relative inline-block mr-2 w-7 h-4'>
                  <Image src={checkmarkImg} className='absolute -top-4' />
                </span>
                Вы подписались на&nbsp;рассылку
                <br />
                «Как сделать проект»
              </H2>
              <div className='mb-8'>
                Проверьте почту. Иногда письма попадают в&nbsp;папку
                <br />
                «Спам» или «Промоакции»
              </div>
              <a href='/getting-project-done-guide/' className='link link-gold'>
                К описанию рассылки
              </a>
            </div>
          </Section>
          <Tools />
          <Footer />
        </div>
      </InvertWrapper>
    </div>
  )
}
