import { useState, useEffect } from 'react'

export default function useBoundingClientRect(ref) {
  const [rect, setRect] = useState({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRect = () =>
    ref.current && setRect(ref.current.getBoundingClientRect())

  useEffect(() => {
    handleRect()
    window.addEventListener('scroll', handleRect)
    window.addEventListener('resize', handleRect)
    return () => {
      window.removeEventListener('scroll', handleRect)
      window.removeEventListener('resize', handleRect)
    }
  }, [handleRect, ref])

  return rect
}
