import React from 'react'
import { Section, Inner8, H2, H3 } from './common'

export default function Letters() {
  return (
    <div className='overflow-hidden'>
      <Section>
        <Inner8>
          <H2>10+&nbsp;выпусков о&nbsp;ведении проекта</H2>
          <p>
            В&nbsp;основе инструкции методология ФФФ&nbsp;— подход
            к&nbsp;запуску проектов в&nbsp;срок без потери качества
            и&nbsp;перерасхода бюджета.
          </p>
        </Inner8>
        <div className='md:grid grid-cols-4 gap-4'>
          <div className='mt-6'>
            <H3 className='text-gold'>Подготовка</H3>
            <ul>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/5e02cbd0-2b9c-4c71-9cbc-d81fc738800f'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Знакомство с&nbsp;задачей
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/d2c879b6-e0c9-4c3f-93ea-b0eaf0547d42'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Понимание задачи
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/600250c4-c456-4cb9-95ae-7f5bc39cec4b'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  План проекта
                </a>
              </li>
            </ul>
          </div>
          <div className='mt-6'>
            <H3 className='text-purple'>Работа</H3>
            <ul>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/b95cd5fe-7b8a-48a2-b44e-3e239c83ae1e'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Старт и&nbsp;первая итерация
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/60bb52c3-0612-4831-a788-b6c162663d12'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Скучная середина проекта
                </a>
              </li>
            </ul>
          </div>
          <div className='mt-6'>
            <H3 className='text-cyan'>Запуск</H3>
            <ul>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/ea08ef6e-da2f-4401-9524-7cd6a3954e16'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Точка невозврата
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/c73e7026-1131-4ec7-99f3-2c5148a2e203'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Полировка и&nbsp;дожим
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/3cdcbe41-7d07-46ef-b147-44b13f8027e1'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Запуск
                </a>
              </li>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/0db1ff0e-1b16-4695-bc22-b564074ae402'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Доработка после&nbsp;пуска
                </a>
              </li>
            </ul>
          </div>
          <div className='mt-6'>
            <H3>После&nbsp;проекта</H3>
            <ul>
              <li className='mb-4'>
                <a
                  href='https://boosty.to/fffworks/posts/9ccae89c-e249-4f7f-a886-e21fea6e72ca'
                  className='link link-black'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Портфолио
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Section>
    </div>
  )
}
