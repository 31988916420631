import React from 'react'
import { Image } from '../../components/Image'
import Cloud from './Cloud'
import footerImg from './img/footer.png'
import s from './styles.module.css'

export default function Footer() {
  return (
    <div className='relative overflow-hidden'>
      <div className='max-w-6xl mx-auto'>
        <Cloud
          className='absolute'
          style={{ transform: 'translate3d(45%, 0, 0) rotate(180deg)' }}
        />
      </div>
      <div className='relative'>
        <div className={s.footerImgHolder}>
          <Image src={footerImg} className={s.footerImg} />
        </div>
      </div>
    </div>
  )
}
