import React from 'react'
import { Image } from '../../components/Image'
import { MainSection, Card, CardLink, H2 } from './common'
import imgAB from './img/a-b-reality.jpg'

export default function HowItWorks() {
  return (
    <MainSection id='how-it-works'>
      <CardLink href='https://bureau.ru/about/fff/?utm_source=fff-works&utm_medium=index'>
        <Card>
          <div className='absolute mt-8 ml-4 md:ml-8'>
            <H2 className='hoverable mb-1'>Как работает</H2>
            <p style={{ maxWidth: '12rem' }}>
              Описание подхода на&nbsp;сайте Бюро Горбунова
            </p>
          </div>
          <Image src={imgAB} className='mt-28 md:mt-8' alt='Путь из А в Б' />
        </Card>
      </CardLink>
    </MainSection>
  )
}
