import React from 'react'
import { Helmet } from 'react-helmet'

export default function CommunityPage() {
  return (
    <Helmet>
      <title>ФФФ.воркс</title>
      <meta httpEquiv='refresh' content='0;url=https://boosty.to/fffworks' />
    </Helmet>
  )
}
