import React from 'react'
import Cloud from './Cloud'
import { Section, Inner8, H2, H3 } from './common'

export default function QA() {
  return (
    <Section className='transform translate-z-0'>
      <Inner8>
        <H2>Ответы на&nbsp;вопросы</H2>
        <H3 className='mt-8 mb-4 text-gold'>Кому подойдёт эта инструкция?</H3>
        <p>
          Мы&nbsp;больше 10&nbsp;лет занимаемся проектами и&nbsp;пришли
          к&nbsp;выводу, что совершенно разные, на&nbsp;первый взгляд, проекты
          очень похожи. Проблемы в&nbsp;разных проектах тоже очень похожи,
          будь&nbsp;то строительство дома, разработка мобильного приложения или
          организация семейного отдыха. Мы&nbsp;создавали инструкцию, держа это
          в&nbsp;голове, поэтому она подойдёт всем, кто работает хоть
          с&nbsp;какими-то проектами: дизайнерам, редакторам, руководителям,
          юристам, инженерам, программистам, стартаперам, организаторам
          мероприятий, мамочкам и&nbsp;папочкам.
        </p>

        <H3 className='mt-8 mb-4 text-gold'>
          Можно получить все выпуски разом?
        </H3>
        <p>Да. Вы получите их все и&nbsp;сразу.</p>
      </Inner8>
      <Cloud
        className='absolute'
        style={{ transform: 'translate3d(-30%, 10%, 0) rotate(15deg)' }}
      />
    </Section>
  )
}
