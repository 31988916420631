import React from 'react'

export default function Nav({ left, center, right }) {
  return (
    <div className='max-w-screen-xl mx-auto px-4'>
      <nav className='flex items-center justify-between py-2'>
        <div>{left}</div>
        <div>{center}</div>
        <div>{right}</div>
      </nav>
    </div>
  )
}
