import React from 'react'
import { Image } from '../../components/Image'
import {
  DividerSection,
  DividerLabel,
  Grid2md4,
  Card,
  CardLink,
  H3,
  H3Like,
} from './common'
import imgChief from './img/chief.png'
import imgConspectus from './img/conspectus.png'
import imgOctopus from './img/octopus.png'

export default function Tools() {
  return (
    <DividerSection>
      <Grid2md4>
        <div>
          <H3>Услуги и&nbsp;инстру&shy;менты</H3>
        </div>
        <CardLink href='/chief/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <Image src={imgChief} alt='Обложка услуги «Шефство»' />
          </Card>
          <DividerLabel>Личный рост</DividerLabel>
          <H3Like className='hoverable'>Шефство</H3Like>
        </CardLink>
        <CardLink href='https://conspectus.io/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <Image src={imgConspectus} alt='Обложка инструмента «Конспект»' />
          </Card>
          <DividerLabel>Заметки на&nbsp;встречах</DividerLabel>
          <H3Like className='hoverable'>Конспект</H3Like>
        </CardLink>
        <CardLink href='/octopus/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <Image
              src={imgOctopus}
              alt='Обложка инструмента «Осьминожка навыков»'
            />
          </Card>
          <DividerLabel>Визуализация навыков</DividerLabel>
          <H3Like className='hoverable'>Осьми&shy;ножка</H3Like>
        </CardLink>
      </Grid2md4>
    </DividerSection>
  )
}
