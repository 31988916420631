/* eslint-disable jsx-a11y/heading-has-content */

import React from 'react'
import cn from '../../helpers/classnames'
import { BaseSection } from '../../components/BaseSection/BaseSection'
import s from './styles.module.css'

export const H1 = (props) => (
  <h1
    {...props}
    className={cn(
      'text-2.5rem md:text-7xl leading-110 font-bold mb-5',
      s.fontBold,
      props.className
    )}
  />
)

export const H2 = (props) => (
  <h2
    {...props}
    className={cn(
      'max-w-lg text-3xl md:text-2.5rem leading-110 mb-5',
      s.fontBold,
      props.className
    )}
  />
)

export const H3 = (props) => (
  <h2
    {...props}
    className={cn('text-xl leading-135 font-bold', s.fontBold, props.className)}
  />
)

export const Section = ({ id, ...rest }) => (
  <BaseSection id={id || ''}>
    <div
      {...rest}
      className={cn('py-8 md:py-12 text-xl leading-135', rest.className)}
    />
  </BaseSection>
)

export const InvertWrapper = (props) => (
  <div
    {...props}
    className={cn('relative bg-black text-white', props.className)}
  />
)

export const Inner8 = (props) => (
  <div className='lg:grid grid-cols-12 gap-4'>
    <div className='col-span-2' />
    <div {...props} className={cn('col-span-8', props.className)} />
    <div className='col-span-2' />
  </div>
)

export const Inner10 = (props) => (
  <div className='lg:grid grid-cols-12 gap-4'>
    <div className='col-span-1' />
    <div {...props} className={cn('col-span-10 ', props.className)} />
    <div className='col-span-1' />
  </div>
)
