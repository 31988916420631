import React from 'react'
import { Image } from '../../components/Image'
import { DividerSection, Grid2md4, Card, CardLink, H3 } from './common'
import imgDeadline from './img/tips-deadline.png'
import imgAzy from './img/tips-azy.png'
import imgSdelat from './img/tips-sdelat.png'
import imgWork from './img/tips-work.png'

export default function Tips() {
  return (
    <DividerSection>
      <Grid2md4>
        <CardLink href='https://bureau.ru/books/fff/demo/4/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <Image
              src={imgSdelat}
              alt='Обложка подборки «Что значит „сделать“»'
            />
          </Card>
        </CardLink>
        <CardLink href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/azy-upravleniya-proektom/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <Image
              src={imgAzy}
              alt='Обложка подборки «Азы управления проектом»'
            />
          </Card>
        </CardLink>
        <CardLink href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/rabota-po-fff/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <Image src={imgWork} alt='Обложка подборки «Работа по ФФФ»' />
          </Card>
        </CardLink>
        <CardLink href='https://bureau.ru/soviet/selected/nikolay-toverovskiy/kak-uspevat-k-dedlaynu/?utm_source=fff-works&utm_medium=index'>
          <Card>
            <Image
              src={imgDeadline}
              alt='Обложка подборки «Как успевать к дедлайну»'
            />
          </Card>
        </CardLink>
      </Grid2md4>
      <div className='flex mt-6'>
        <div className='md:w-9/12'>
          <H3>Подборки советов</H3>
          <div>
            Советы&nbsp;— рубрика на&nbsp;сайте Бюро Горбунова. Подборки
            об&nbsp;управлении проектами будут полезны для&nbsp;первого
            знакомства с методом.
          </div>
        </div>
      </div>
    </DividerSection>
  )
}
