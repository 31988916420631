import React from 'react'
import s from './button.module.css'

const Button = (props) => {
  console.log(props)
  const cn = [
    props.small && s.buttonSmall,
    !props.small && s.button,
    props.transparent && s.buttonTransparent,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <React.Fragment>
      {props.href && (
        <a
          className={cn}
          onClick={props.onClick}
          target={props.target}
          rel={props.rel}
          href={props.href}
        >
          {props.children}
        </a>
      )}
      {!props.href && (
        <button className={cn} onClick={props.onClick}>
          {props.children}
        </button>
      )}
    </React.Fragment>
  )
}

export default Button
