const NBSP = '\u00a0'
const defaultPluralizeOptions = { one: '', two: '', five: '' }

/**
 * Возвращает подходящую форму слова для числа
 *
 * @param value   Число для плюрализации
 * @param options Формы слова
 */
export const getPluralizedForm = (value, options = defaultPluralizeOptions) => {
  const { one, two, five } = options
  let n = Math.abs(value)

  n %= 100
  if (n >= 5 && n <= 20) {
    return five
  }

  n %= 10

  if (n === 1) {
    return one
  }
  if (n >= 2 && n <= 4) {
    return two
  }

  return five
}

/**
 * Возвращает число с соответствующей формой слова
 *
 * @param value   Число для плюрализации
 * @param options Формы слова
 */
export const pluralize = (value, options = defaultPluralizeOptions) => {
  const suffix = getPluralizedForm(value, options)

  return value + NBSP + suffix
}
