import Spacer from 'components/Spacer/Spacer'
import { Image } from '../../components/Image'
import React from 'react'
import abImg from './img/a-b.png'

export default function AB() {
  return (
    <div className='relative overflow-hidden 2xl:overflow-visible max-w-6xl mx-auto'>
      <div
        className='relative'
        style={{
          marginLeft: '-15%',
          marginRight: '-5%',
          width: '120%',
          maxWidth: '120%',
        }}
      >
        <Image src={abImg} className='absolute' />
        <Spacer ratio='2456/1166' />
      </div>
    </div>
  )
}
