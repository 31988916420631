import React from 'react'

const isCtrlG = (e) => e.ctrlKey && e.key === 'g'

export default function DebugGrid() {
  const [isVisible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    const handleHotkey = (e) => isCtrlG(e) && setIsVisible(!isVisible)
    document.addEventListener('keyup', handleHotkey)
    return () => document.removeEventListener('keyup', handleHotkey)
  })

  if (!isVisible) return null

  return (
    <div className='sticky h-0 inset-0 max-w-screen-xl mx-auto px-4 grid grid-cols-12 gap-4 pointer-events-none transform translate-z-0 z-50'>
      {new Array(12).fill(0).map((_, idx) => (
        <div className='h-screen bg-cyan bg-opacity-20' key={idx} />
      ))}
    </div>
  )
}
